import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { store } from "./store";

export const getPersonId = gql`
  query getPersonId($email: String) {
    person(where: { email: { _eq: $email }, deleted_at: { _is_null: true } }) {
      id
      first_name
      coupons {
        code
      }
    }
  }
`;

export const usePersonId = () => {
  const { data, error, loading } = useQuery(getPersonId, {
    variables: { email: store.email },
  });
  console.log("Error: ", error);
  console.log("Data: ", data);
  return { id: data.person[0] ? data.person[0].id : null, error, loading };
};

export const upsertCompletion = gql`
  mutation upsert_completion(
    $activity: Int
    $person: Int
    $completed: Boolean
    $comment: String
    $date_done: date
    $file: String
    $file_date: date
    $file_video_etc: String
    $file_folder_etc: String
    $file_summary_etc: String
  ) {
    insert_activity_completion(
      objects: [
        {
          activity: $activity
          person: $person
          completed: $completed
          comment: $comment
          date_done: $date_done
          file: $file
          file_date: $file_date
          file_video_etc: $file_video_etc
          file_folder_etc: $file_folder_etc
          file_summary_etc: $file_summary_etc
        }
      ]
      on_conflict: {
        constraint: unique_completion
        update_columns: [
          completed
          comment
          date_done
          file
          file_date
          file_video_etc
          file_folder_etc
          file_summary_etc
        ]
      }
    ) {
      affected_rows
      returning {
        id
        completed
        comment
        date_done
      }
    }
  }
`;

export const completionFieldsFragment = gql`
  fragment completionFields on activity_completion {
    person
    activity
    comment
    completed
    date_done
    file
    file_date
    file_video_etc
    file_folder_etc
    file_summary_etc
  }
`;

export const allStudentsWithActivivitiesNoMonitors = gql`
  query allStudents($turma: Int) {
    person(
      where: {
        enrollments: {
          registers: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc }
    ) {
      id
      first_name
      last_name
      city
      state
      email
      cpf
      cell_phone
      activity_completions(
        where: {
          completed: { _eq: true }
          activityByActivity: { lessonByLesson: { turma: { _eq: $turma } } }
          deleted_at: { _is_null: true }
        }
      ) {
        id
        completed
        comment
        date_done
        monitor_comment
        monitor_file
        file
        file_date
        file_summary_etc
        file_folder_etc
        file_video_etc
        activityByActivity {
          id
          title
          type
          ui_order
          lessonByLesson {
            id
            number
            turma
          }
        }
      }
      enrollments(
        where: {
          registers: { turma: { _eq: $turma } }
          deleted_at: { _is_null: true }
        }
      ) {
        registers(
          where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
        ) {
          is_professor
          is_tutor
          is_team_member
          is_monitor
          is_audit
          is_evaluator
          suspended
          cancelled
          locked
          dependency
        }
      }
    }
  }
`;

export const allStudentsWithActivivities = gql`
  query allStudents($turma: Int, $monitor: Int) {
    person(
      where: {
        enrollments: {
          registers: { turma: { _eq: $turma } }
          deleted_at: { _is_null: true }
        }
        monitores: {
          monitor: { _eq: $monitor }
          deleted_at: { _is_null: true }
        }
        deleted_at: { _is_null: true }
      }
      order_by: { first_name: asc }
    ) {
      id
      first_name
      last_name
      city
      state
      email
      cpf
      cell_phone
      activity_completions(
        where: {
          completed: { _eq: true }
          activityByActivity: { lessonByLesson: { turma: { _eq: $turma } } }
          deleted_at: { _is_null: true }
        }
      ) {
        id
        completed
        comment
        date_done
        monitor_comment
        monitor_file
        file
        file_date
        file_summary_etc
        file_folder_etc
        file_video_etc
        activityByActivity {
          id
          title
          type
          ui_order
          lessonByLesson {
            id
            number
            turma
          }
        }
      }
      enrollments(
        where: {
          registers: { turma: { _eq: $turma } }
          deleted_at: { _is_null: true }
        }
      ) {
        registers(
          where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
        ) {
          is_professor
          is_tutor
          is_team_member
          is_monitor
          is_audit
          is_evaluator
          suspended
          cancelled
          locked
          dependency
        }
      }
    }
  }
`;

export const getLessonNumbers = gql`
  query getLessonNumbers($turmaId: Int) {
    lesson(
      where: { turma: { _eq: $turmaId }, deleted_at: { _is_null: true } }
      order_by: { number: asc }
    ) {
      id
      number
    }
  }
`;

export const getBaseStudentData = gql`
  query getBaseStudentData($email_monitor: String, $today: date) {
    person(
      where: { email: { _eq: $email_monitor }, deleted_at: { _is_null: true } }
    ) {
      id
      enrollments(order_by: { course: asc }) {
        id
        registers(
          order_by: { turma: desc }
          where: {
            cancelled: { _eq: false }
            suspended: { _eq: false }
            expired: { _eq: false }
            deleted_at: { _is_null: true }
          }
        ) {
          id
          turmaByTurma {
            id
            name
            category_name
            welcome_message
            max_activity_characters
            max_activity_words
            course
            deadline
            courseByCourse {
              org
            }
          }
        }
        payment_plans {
          payments(
            where: {
              due_date: { _lt: $today }
              _or: [
                { status: { _eq: "pending" } }
                { status: { _eq: "expired" }, disabled: { _eq: false } }
              ]
              deleted_at: { _is_null: true }
            }
            order_by: { due_date: asc }
          ) {
            due_date
          }
        }
      }
      monitorandos {
        id
        personByStudent {
          id
        }
      }
      project_students {
        id
      }
      consulting {
        id
        email
      }
      courses_managed {
        course
      }
    }
  }
`;
export const getTutor = gql`
  query isTutor($email_tutor: String) {
    student_tutor_view(where: { tutor_email: { _eq: $email_tutor } }) {
      tutor_id
      student_email
      turma_id
    }
  }
`;

export const activitiesCounts = gql`
  query noActivities($turma: Int, $monitor: Int) {
    person(
      where: {
        enrollments: { registers: { turma: { _eq: $turma } } }
        monitores: { monitor: { _eq: $monitor } }
        deleted_at: { _is_null: true }
      }
    ) {
      first_name
      last_name
      activity_completions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const log = gql`
  mutation log($personId: Int, $action: String, $payload: String) {
    insert_log(
      objects: [{ person: $personId, log_action: $action, payload: $payload }]
    ) {
      returning {
        id
      }
    }
  }
`;

export const getAllActivities = gql`
  query getAllActivitiesAndCompletions($email: String, $turmaId: Int) {
    lesson(
      where: { turma: { _eq: $turmaId }, deleted_at: { _is_null: true } }
      order_by: { number: asc }
    ) {
      id
      number
      title
      start_date
      professor
      available
      disciplinaByDisciplina {
        name
      }
      activities(
        order_by: { ui_order: desc }
        where: { deleted_at: { _is_null: true } }
      ) {
        id
        title
        description
        video_url
        type
        video_aspect_ratio
        available
        slides_download_link
        ui_order
        deadline
        activity_completions(
          where: {
            personByPerson: { email: { _eq: $email } }
            deleted_at: { _is_null: true }
          }
        ) {
          id
          completed
          comment
          date_done
          monitor_comment
          monitor_file
          file
          file_date
          file_video_etc
          file_folder_etc
          file_summary_etc
        }
        activity_type {
          id
          group
          upload
          name
        }
      }
    }
    quiz_relation(
      where: {
        turma: { _eq: $turmaId }
        deleted_at: { _is_null: true }
        quizByQuiz: { _not: { disciplina_id: { _eq: 158 } } }
      }
    ) {
      quizByQuiz {
        id
        name
        created_at
        questions(where: { deleted_at: { _is_null: true } }) {
          id
          pontos
        }
        quiz_attempts(
          where: {
            personByPerson: { email: { _eq: $email } }
            deleted_at: { _is_null: true }
          }
        ) {
          id
          quiz
          person
          question_completions(where: { deleted_at: { _is_null: true } }) {
            id
            answer
            question_option {
              id
              correct
              questionByQuestion {
                pontos
                null_question
              }
            }
          }
        }
      }
    }
  }
`;

export const ClassHours = gql`
  query Hours($personId: Int!, $turmaId: Int!) {
    student_schedule(
      where: {
        person: { _eq: $personId },
        turma: { _eq: $turmaId }
      }
    ) {
      class_schedule
    }
  }
`;

export const removeActivity = gql`
  mutation RemoveActivity($id: Int!, $deleted_at: timestamp) {
    update_activity_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const removeLesson = gql`
  mutation RemoveLesson($id: Int!, $deleted_at: timestamp) {
    update_activity(
      where: { lesson: { _eq: $id }, deleted_at: { _is_null: true } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
    }
    update_lesson_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`;

export const GET_GRADES = gql`
  query GradesStudent($email: String, $turma: Int) {
    disciplina_grades(
      where: {
        person: { email: { _eq: $email } }
        turma: { id: { _eq: $turma } }
        disciplina_id: { _neq: 158 }
      }
    ) {
      id
      is_final
      grade
      attempts
      disciplina {
        name
        id
      }
    }
  }
`;

export interface Completions {
  id: number;
  completed: boolean;
  comment: string;
  date_done: string;
  monitor_comment: string;
  monitor_file: string;
  file_video_etc: string;
  file_folder_etc: string;
  file_summary_etc: string;
  activityByActivity: ActivityInterface;
}

export interface ActivityInterface {
  id: number;
  title: string;
  description: string;
  video_url?: string;
  type: string;
  video_aspect_ratio?: string;
  available: boolean;
  slides_download_link?: string;
  ui_order: number;
  activity_completions: [Completions];
  lessonByLesson: lessonInterface;
  deleted_at: Date;
  deadline: string;
}

export interface lessonInterface {
  id: number;
  number: number;
  title: string;
  start_date: string;
  professor: string;
  available: boolean;
  activities: [ActivityInterface];
  turma: number;
  disciplinaByDisciplina: {
    name: string;
  };
}

export interface quizInterface {
  quizByQuiz: {
    id: number;
    name: string;
    created_at: string;
    quiz_attempts: [quizAttemptInterface];
    questions: [questionInterface];
  };
}

export interface questionInterface {
  id: number;
  answer: number;
  pontos: number;
  null_question: boolean;
}

export interface questionOptionInterface {
  id: number;
  correct: boolean;
  question: questionInterface;
}

export interface quizAttemptInterface {
  id: number;
  quiz: number;
  person: number;
  question_completions: [questionCompletion];
}

export interface questionCompletion {
  id: number;
  answer: number;
  question_option: questionOptionInterface;
}

export interface getAllACtivitiesInterface {
  lesson: [lessonInterface];
  quiz_relation: [quizInterface];
}

export const updateLessonAvailability = gql`
  mutation UpdateLessonAvailability($id: Int!, $available: Boolean!) {
    update_lesson_by_pk(
      pk_columns: { id: $id }
      _set: { available: $available }
    ) {
      id
      available
    }
  }
`;
